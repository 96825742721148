import { useTheme } from '@emotion/react';
import { CSSProperties } from 'react';
import Footer from './Footer';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import Navbar from './components/Navigation/Navbar';
import use100vh from './hooks/use100vh';

type Props = {
  children: React.ReactNode;
  footer?: React.ReactNode;
};

const NextPage = (props: Props) => {
  const { children, footer = <Footer /> } = props;
  const theme = useTheme();

  const partnerTheme = {
    '--button-background': theme.alias.background.contrast_100,
    '--button-hover-background': theme.alias.background.contrast_200,
    '--button-text-color': theme.alias.text_300,
  } as CSSProperties;

  return (
    <>
      <div
        style={{ height: use100vh(), ...partnerTheme }}
        className="relative flex flex-col bg-white"
      >
        <Navbar />
        <div className="flex-[1_0_auto] grow-[1] flex-col">
          <ErrorBoundary name="Storefront top level error boundary">
            <main className="h-full">{children}</main>
          </ErrorBoundary>
        </div>

        {footer}
      </div>
    </>
  );
};

export default NextPage;
